import { isAppleEmail } from '@/libs/tools'
import { getInviteCode, getShareKey, getShareType, getToken, getTokenExpired, getTokenUid } from '@/services/store'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  // 邀请有礼分享码
  const shareKey = ref(getShareKey())
  // 邀请有礼分享类型：
  const shareType = ref(getShareType())
  // 当前登录用户token
  const token = ref(getToken())
  // 当前登录用户token过期时间
  const tokenExpired = ref(getTokenExpired())
  // 当前用户uid
  const uid = computed(() => getTokenUid(token.value))
  // 当前用户是否是企业用户
  const companyUser = computed(() => Boolean(userInfo?.value?.companyUser))
  // 当前用户国家
  const country = ref('')
  // 当前登录账号是否存在
  const accountIsExist = ref(false)
  // 当前登录账号是否存在密码
  const passwordIsExist = ref(false)
  // 登录注册表单
  const loginFormData = ref({
    username: '',
    accountType: 'email',
    codeId: '', // 验证码id
    isRegister: 0,
  })
  // 当前登录用户信息
  const userInfo = ref({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyUser: false,
    work: '',
    ageMin: '',
    ageMax: '',
    isSSO: false,
    role: 0,
    floatAuth: 0,
    hasPassword: false,
  })
  // 企业邀请用户邀请码
  const inviteCode = ref(getInviteCode())
  // 客户端已登录的用户uid集合
  const loggedAccounts = ref([])
  // 用户plan列表(目前只针对个人用户)
  const planList = ref([])
  // 是否可修改密码(有邮箱/手机号，非sso，非隐私账号)
  const canChangePassword = computed(() => {
    return (userInfo.value.email || userInfo.value.phone) && !userInfo.value.isSSO && !isAppleEmail(userInfo.value.email)
  })
  // 用户姓名或昵称
  const nickName = computed(() => {
    const nickname = (userInfo.value?.firstName + ' ' + userInfo.value?.lastName).trim()
    return nickname || userInfo.value?.nickName
  })
  return {
    uid,
    shareKey,
    shareType,
    token,
    tokenExpired,
    companyUser,
    country,
    inviteCode,
    loggedAccounts,
    loginFormData,
    userInfo,
    planList,
    canChangePassword,
    nickName,
    accountIsExist,
    passwordIsExist,
  }
})
