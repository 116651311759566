import Base64 from 'base-64'
import SparkMD5 from 'spark-md5'
import utf8 from 'utf8'
import config from '@/config'
import axios from 'axios'
import { getReferrer } from '@/services/base'
import { compareVersions, getOSName, getSafariVersion, isSafari, setCookie } from './tools'
import { useAppStore } from '@/stores/modules/appStore'
import { useUserStore } from '@/stores/modules/userStore'
import { isObject } from 'lodash'
import $router from '@/router'

/**
 * 设置本地缓存
 * @param {*} key
 * @param {*} value
 */
export const setLocalStorage = (key, value) => {
  if (!value) localStorage.removeItem(key)
  else localStorage.setItem(key, value)
}

/**
 * 获取本地缓存
 * @param {String} key
 * @returns {String}
 */
export const getLocalStorage = key => {
  return localStorage.getItem(key)
}

/**
 * 设置或删除sessionStorage
 * @param {*} key
 * @param {*} value
 */
export const setSessionStorage = (key, value = '') => {
  if (!value) sessionStorage.removeItem(key)
  else sessionStorage.setItem(key, value)
}

/**
 * 获取sessionStorage
 * @param {*} key
 * @returns
 */
export const getSessionStorage = key => {
  return sessionStorage.getItem(key)
}

/**
 * 获取系统支持的语言名称，兼容其他端传过来的非常规格式的参数，比如：en-US-locale,en-us 等等
 * 如果不在系统支持的语言列表里，则默认返回en-US
 * @param {String} language 客户端等传过来的语言名称
 * @returns 处理后的系统定义的标准的语言名称
 */
export const getSupportLanguage = (language = '') => {
  language = language ? language.substring(0, 5) : 'en-US'
  const languageArr = language.split('-')
  if (languageArr.length === 2) {
    languageArr[1] = languageArr[1].toUpperCase()
  }
  language = languageArr.join('-')
  if (config.localeLangs[language]) {
    return language
  }
  return 'en-US'
}

/**
 * 添加错误调试信息
 * @param {Object|Array|String} info 错误信息
 */
export const pushDebugInfo = info => {
  const appStore = useAppStore()
  if (isInWebview()) {
    let infoStr = info
    if (typeof info === 'object') {
      infoStr = JSON.stringify(info)
    }
    appStore.debugInfo = `
${new Date().toLocaleString()}
${infoStr}

${appStore.debugInfo}
`
  }
}

/**
 * 清空错误调试信息
 */
export const clearDebugInfo = () => {
  const appStore = useAppStore()
  appStore.debugInfo = ''
}

/**
 * 储存token到cookie中
 * @param {String} token
 * @param {Number} expired 过期时间毫秒
 */
export const setToken = (token, expired = 0) => {
  const userStore = useUserStore()
  // 如果expired是秒，则转化为毫秒
  expired = expired.toString()?.length === 10 ? expired * 1000 : expired
  const tokenData = {
    token,
    expired,
  }
  if (!token) {
    userStore.token = ''
    userStore.tokenExpired = 0
    setCookie(config.tokenKey, '', -1, true)
    setCookie(config.tokenExpiredKey, '', -1, true)
  } else {
    userStore.token = token
    userStore.tokenExpired = expired
    const expiredSeconds = parseInt((tokenData.expired - new Date().getTime()) / 1000)
    setCookie(config.tokenKey, tokenData.token, expiredSeconds, true)
    setCookie(config.tokenExpiredKey, tokenData.expired, expiredSeconds, true)
  }
}

/**
 * 获取并设置国家缓存
 */
export const setCountry = (excludeTokens = []) => {
  const userStore = useUserStore()
  let country = getLocalStorage('country')
  if (country) {
    userStore.country = country
  } else {
    const tokenArr = [
      // updfinbr@gmail.com, updfines@gmail.com, tshiwu0@gmail.com, wangfengaf@gmail.com
      // eslint-disable-next-line prettier/prettier
      '4d212e3751c1cc', '8af165d234ea74', '2af3bf4b9244ea', 'f8d05f4dd92b02'
    ].filter(item => !excludeTokens.includes(item))
    const token = tokenArr[0]
    if (!token) {
      return false
    }
    axios
      .get(`https://ipinfo.io/country?token=${token}`)
      .then(res => {
        country = res.data.trim()
        setLocalStorage('country', country)
        userStore.country = country
      })
      .catch(err => {
        console.log(err)
        excludeTokens.push(token)
        setCountry(excludeTokens)
      })
  }
}

/**
 * 通过userAgent检测是否是在MAC客户端的内嵌网页
 * @returns {boolean}
 */
export const isInMacWebview = () => {
  const { userAgent } = navigator
  return userAgent.includes('UPDF') && userAgent.includes('Mac OS')
}

/**
 * 通过userAgent检测是否是在Win客户端的内嵌网页
 * @returns {boolean}
 */
export const isInWinWebview = () => {
  const { userAgent } = navigator
  return userAgent.includes('UPDF') && userAgent.includes('Windows')
}

/**
 * 是否是在客户端内嵌页
 * @returns {boolean}
 */
export const isInWebview = () => {
  return isInMacWebview() || isInWinWebview()
}

/**
 * 获取Windows客户端版本号
 * @returns {String|null}
 */
export const getWinAppVersion = () => {
  if (isInWinWebview()) {
    const versionMatch = navigator.userAgent.match(/UPDF ([.\d]+)/)
    if (versionMatch) return versionMatch[1]
  }
  return null
}

/**
 * 发送消息给父窗口
 * @param {String} action 操作事件名
 * @param {Object} params 其他自定义参数
 */
export const postMessageToOpenerWindow = (action, params = {}) => {
  const parentWindow = window.opener || window.parent
  if (parentWindow) {
    let targetOrigin = '*'
    // 正式环境安全起见发送消息时需要限制父窗口来源
    if (import.meta.env.MODE === 'prod') {
      // 如果是弹窗形式(或iframe方式)，targetOrigin 设置为父窗口或页面地址即可
      if (window.name === 'accountNewWindow') {
        targetOrigin = parentWindow.location.href
      } else {
        targetOrigin = getReferrer() || location.href
      }
      const url = new URL(targetOrigin)
      // 访问来源必须是在updf.com 或 updf.cn域名下
      if (url.origin.includes('updf.com') || url.origin.includes('updf.cn')) {
        targetOrigin = url.origin
      } else {
        targetOrigin = 'https://updf.com'
      }
    }
    parentWindow.postMessage({ action, params }, '*')
  }
}

/**
 * 跳转到软件，协议默认为updf://，协议名称updf可由客户端引入链接时通过传参schemeName=updf来自定义
 * @param {String} action 操作类型:
 *                        logOut:退出登录
 *                        loginClose: 网页端关闭登录页面
 *                        loginSuccess: 登录成功
 *                        openBrowser: 打开浏览器，params={url:打开的地址}
 *                        closeWindow: PC端关闭webview窗口
 * @param {Object} params 参数:其中如果有key为data的会自动转为base64加密后的json格式，
 *                        除data参数可以为object以外，其他参数值必须是字符串或数字
 *                        比如:params={data:{name:'小明'}},最终跳转为updf://xxx?data=eyJuYW1lIjoi5bCP5piOIn0=
 * 最终地址格式为：updf://{actionName}?{key1}={value1}
 * @param {String} redirectType 指定通知或跳转类型，
 *                        default: 默认default根据设备类型来通知或跳转
 *                        postMessage: 指定通知父页面或父窗口
 */
export const redirectApp = (action, params, redirectType = 'default') => {
  const appStore = useAppStore()
  const isIframe = appStore.isIframe
  // 网页登录，通知父窗口
  if (isIframe || redirectType === 'postMessage') {
    console.log('通知父窗口')
    postMessageToOpenerWindow(action, params)
    return
  }
  // 打开app的情况下
  let schemeName = appStore.schemeName
  if (!schemeName) {
    const OSName = getOSName()
    if (isInMacWebview() || OSName === 'MAC') {
      schemeName = 'updf'
    } else if (isInWinWebview() || OSName === 'WIN') {
      schemeName = 'winupdf'
    } else if (OSName === 'IOS' || OSName === 'ANDROID') {
      schemeName = 'updf'
    }
  }
  if (schemeName) {
    const urlQueryArr = []
    for (const i in params) {
      let value = params[i]
      if (i === 'data') {
        value = Base64.encode(utf8.encode(JSON.stringify(value)))
      }
      urlQueryArr.push(`${i}=${value}`)
    }
    // 如果同时发送多个跳转，则需要加延时轮流发送，并增加延迟
    const lastRedirectAppTime = window.lastRedirectAppTime || 0
    const nowTime = new Date().getTime()
    const delayTime = 20
    if (lastRedirectAppTime === 0 || nowTime - lastRedirectAppTime > delayTime) {
      const href = `${schemeName}://${action}?` + urlQueryArr.join('&')
      location.href = href
      pushDebugInfo(href)
      window.lastRedirectAppTime = nowTime
    } else {
      window.lastRedirectAppTime += delayTime
      setTimeout(() => {
        const href = `${schemeName}://${action}?` + urlQueryArr.join('&')
        location.href = href
        pushDebugInfo(href)
      }, lastRedirectAppTime + delayTime - nowTime)
    }
  }
}

/**
 *
 * @param {*} url
 */
// a标签下载execel
export function downLoadExcel(url, fileName) {
  event.preventDefault()

  var xhr = new XMLHttpRequest()
  xhr.open('get', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function () {
    if (this.status === 200) {
      var blob = this.response
      var href = window.URL.createObjectURL(blob) // 创建下载链接
      // 判断是否是IE浏览器，是则返回true
      if (window.navigator.msSaveBlob) {
        try {
          window.navigator.msSaveBlob(blob, '备库申请模板.xlsx')
        } catch (e) {
          console.log(e)
        }
      } else {
        // 非IE浏览器，创建a标签，添加download属性下载
        var a = document.createElement('a') // 创建下载链接
        a.href = href
        a.target = '_blank' // 新开页下载
        a.download = fileName // 下载文件名
        document.body.appendChild(a) // 添加dom元素
        a.click() //  点击下载
        document.body.removeChild(a) // 下载后移除元素
        window.URL.revokeObjectURL(href) // 下载后释放blob对象
      }
    }
  }
  xhr.send()
}

// 生成八位随机密码
export function generateRandomString(length) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var result = ''
  for (var i = 0; i < length; i++) {
    var randomChar = Math.floor(Math.random() * chars.length)
    result += chars[randomChar]
  }
  return result
}
/**
 * 是否是空对象
 * @param {*} obj 
 * @returns 
 */
export function isEmptyObject(obj) {
  if (!isObject(obj)) return false
  return Object.keys(obj).length === 0
}
export function isByteStream(data) {
  return data instanceof Blob
}

/**
 * 返回本地文件的bloburl临时链接
 * @param {*} file 
 * @returns 
 */
export const getFileBlobUrl = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      // 当文件读取完成时，创建一个Blob对象
      const blob = new Blob([event.target.result], {type: file.type});
      // 为Blob对象创建一个URL
      const blobUrl = (window.URL || window.webkitURL).createObjectURL(blob);
      resolve(blobUrl)
    }
    reader.onerror = (err) => {
      reject(err)
    }
    reader.readAsArrayBuffer(file); // 开始读取文件
  })
}

/**
 * 计算文件md5值
 * @param {File} file file对象
 * @returns {String} 文件hash值
 */
export const getFileHash = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function(event) {
      const fileData = event.target.result
      const spark = new SparkMD5.ArrayBuffer()
      spark.append(fileData)
      const hashHex = spark.end()
      resolve(hashHex)
    }
    reader.onerror = function(event) {
      console.log(event)
      reject(event?.target?.result)
    }
    reader.readAsArrayBuffer(file) 
  })
}

/**
 * 针对routerBack在win内嵌页里使用是导致的上一页路由和当前页一样的情况，当从当前页跳转到下一页时使用replace的方式，避免历史记录出现更多重复路由
 * @param {*} name 
 * @param {*} query 
 * @param {*} params 
 */
export const routerTo = (name, query, params) => {
  const state = $router?.options?.history?.state
  if (state?.back === state?.current) {
    $router.replace({
      name,
      query,
      params
    })
  } else {
    $router.push({
      name,
      query,
      params
    })
  }
  return false
}

/**
 * 返回上一页
 * @returns 
 */
export const routerBack = () => {
  pushDebugInfo(`isSafari: ${isSafari()}; safariVersion: ${getSafariVersion()}; isLowSafariVersion: ${compareVersions('16.0', getSafariVersion())}, userAgent: ${navigator.userAgent}`)
  const state = $router?.options?.history?.state
  if (!state) {
    $router.back()
    return
  }
  // win端内嵌页直接使用router.back()会导致页面延迟
  // 低版本safari里点击返回无效
  // 所以在win内嵌页需要使用替换的方式回到上一页，但是这样会导致历史记录中有重复记录，所以如果有重复记录返回时直接返回两层路由
  const isLowSafariVersion = isSafari() && compareVersions('16.0', '15.30') === 1
  if (isInWinWebview() || isInMacWebview() || isLowSafariVersion) {
    if (state?.back === state?.current) {
      $router.go(-2)
    } else {
      $router.replace({
        path: state?.back,
        params: $router?.currentRoute?.value?.params
      })
    }
  } else {
    $router.back()
  }
}