import { getUrlQuery } from '@/libs/tools'
import { getDeviceType, getIsCN, getLanguage, getLoginFrom, getProductName, getSchemeName, getSource, getSubPid } from '@/services/store'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAppStore = defineStore('app', () => {
  const version = ref('2.0.1')
  const isProd = ref(import.meta.env.MODE === 'prod')
  const isUat = ref(import.meta.env.MODE === 'uat')
  const isLocal = ref(import.meta.env.MODE === 'test-local')
  const isDev = ref(import.meta.env.MODE === 'dev')
  // 是否是国内环境
  const isCN = ref(getIsCN())
  // 界面语言
  const language = ref(getLanguage(isCN.value))
  // 客户端交互协议
  const schemeName = ref(getSchemeName())
  // 设备类型
  const deviceType = ref(getDeviceType())
  // 是否是MAC,WIN,IOS,ANDROID
  const isMac = computed(() => deviceType.value === 'MAC')
  const isWin = computed(() => deviceType.value === 'WIN')
  const isiOS = computed(() => deviceType.value === 'IOS')
  const isAndroid = computed(() => deviceType.value === 'ANDROID')
  // 是否是通过iframe引入的
  const isIframe = computed(() => window.top !== window || getUrlQuery('isIframe') === '1')
  const isWeb = computed(() => !isIframe.value && !isMac.value && !isWin.value && !isiOS.value && !isAndroid.value)
  // 产品名称，默认：UPDF
  const productName = ref(getProductName())
  // 注册来源(更细的来源，比如来自官网某个页面),默人：WEB
  const source = ref(getSource())
  // 渠道包 id
  const subPid = ref(getSubPid())
  // 登录来源：checkout，website，online
  const loginFrom = ref(getLoginFrom())
  // 是否是在收银台登录
  const isLoginFromCheckout = computed(() => {
    return loginFrom.value === 'checkout'
  })
  // webview隐藏显示状态,默认显示
  const webviewVisibilityState = ref(true)
  // 调试信息：Object或String
  const debugInfo = ref('')
  // 是否是从 appStore 来的
  const isAppStore = ref(Boolean(parseInt(getUrlQuery('isAppStore') || 0)))
  // 是否使用zga域名接口，正式环境国内默认使用，如果遇到接口超时，则再禁用zga访问
  const useZgaApi = ref(isProd.value && isCN.value)
  const zgaApiHost = ref(isLocal.value ? 'zga-test-account.updf.cn' : import.meta.env.VITE_API_PREFIX_CN_ZGA)
  // 默认登录账号，用于默认填充登录页
  const defaultAccount = ref(getUrlQuery('account') || '')
  // 登录时是否可修改账号(前提是有默认账号)
  const disabledAccountInput = ref(Boolean(getUrlQuery('disabledAccountInput')))
  return {
    version,
    isProd,
    isUat,
    isLocal,
    isDev,
    language,
    isCN,
    schemeName,
    deviceType,
    isMac,
    isWin,
    isiOS,
    isAndroid,
    isIframe,
    isWeb,
    productName,
    source,
    subPid,
    loginFrom,
    isLoginFromCheckout,
    webviewVisibilityState,
    debugInfo,
    isAppStore,
    useZgaApi,
    zgaApiHost,
    defaultAccount,
    disabledAccountInput,
  }
})
