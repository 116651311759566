const env = import.meta.env
const mode = env.MODE
const websiteHostCom = env.VITE_WEBSITE_HOST_COM
const websiteHostCn = env.VITE_WEBSITE_HOST_CN
const isProd = mode === 'prod'
export default {
  // token存放cookie key
  tokenKey: 'xToken' + (!isProd ? `-${mode}` : ''),
  // token过期时间cookie key
  tokenExpiredKey: 'xTokenExpired' + (!isProd ? `-${mode}` : ''),
  iframeReadyActionName: 'iframeOnReady', // iframe文档加载完毕时通知到父窗口的 action 名字
  googleClientId: env.VITE_GOOGLE_CLIENT_ID, // 谷歌登录客户端ID
  googleAllowOrigin: env.VITE_GOOGLE_ALLOW_ORIGIN, // 谷歌一键登录允许iframe加载的域名
  ossApp: { // 其他文件上传配置，如反馈意见等临时文件
    'cn-east': { // 中区
      prefix: env.VITE_OSS_APP_PREFIX_CN, // oss 访问前缀
      region: env.VITE_OSS_APP_REGION_CN, // oss 上传地区
    },
    'us-east': { // 美区
      prefix: env.VITE_OSS_APP_PREFIX_EN,
      region: env.VITE_OSS_APP_REGION_EN,
    }
  },
  localeLangs: {
    // 目前支持的语言列表
    'en-US': {
      websiteHome: `${websiteHostCom}/`,
      name: 'English',
    },
    'de-DE': {
      websiteHome: `${websiteHostCom}/de/`,
      name: 'German - Deutsch',
    },
    'es-ES': {
      websiteHome: `${websiteHostCom}/es/`,
      name: 'Spanish - Español',
    },
    'fr-FR': {
      websiteHome: `${websiteHostCom}/fr/`,
      name: 'French - Français',
    },
    'it-IT': {
      websiteHome: `${websiteHostCom}/it/`,
      name: 'Italian - Italiano',
    },
    'ja-JP': {
      websiteHome: `${websiteHostCom}/jp/`,
      name: 'Japanese - 日本語',
    },
    'pt-BR': {
      websiteHome: `${websiteHostCom}/br/`,
      name: 'Portuguese - Português',
    },
    'ko-KR': {
      websiteHome: `${websiteHostCom}/kr/`,
      name: 'Korean - 한국어',
    },
    'ar-SA': {
      websiteHome: `${websiteHostCom}/ar/`,
      name: 'العربية - 阿拉伯语',
    },
    'zh-CN': {
      websiteHome: `${websiteHostCn}/`,
      name: 'Chinese - 简体中文',
    },
    'zh-HK': {
      websiteHome: `${websiteHostCom}/hk/`,
      name: 'Chinese - 繁体中文',
    },
  },
}
