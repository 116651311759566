import { createApp } from 'vue'
import App from './App.vue'
import i18n from './locale'
import routers from './router'
import 'element-plus/dist/index.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { createPinia } from 'pinia'
// import './mock/index'
// import { sentryInit } from './libs/plugins/sentryInit'

const app = createApp(App).use(createPinia()).use(i18n).use(routers)
// 整合sentry
// sentryInit(app, routers, store)
// 全局变量使用方式
// const { $t } = getCurrentInstance().proxy
// console.log($t('test'))
const { t, locale } = i18n.global
app.config.globalProperties.$t = t
// locale 用来切换语言，locale.value = 'en-US'
app.config.globalProperties.locale = locale

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.tz.guess()
app.use(VueSvgInlinePlugin, {
  cache: {
    version: '1.0.5',
  },
})
app.mount('#app')
