import config from '@/config'
import Base64 from 'base-64'
import { getCookie, getOSName, getUrlQuery } from '@/libs/tools'
import { getLocalStorage, getSessionStorage, getSupportLanguage, setLocalStorage, setSessionStorage } from '@/libs/utils'

/**
 * 判断是否是中文环境
 * @returns {boolean}
 */
export const getIsCN = () => {
  // 默认 uat 对应海外，local对应国内
  const mode = import.meta.env.MODE
  let testIsCN = mode !== 'uat'
  // 本地或线上测试地址可以修改localStorage isCN=1 或者 url中设置isCN=1修改中英文界面
  const cacheIsCN = getUrlQuery('isCN') || getUrlQuery('isZh') || getLocalStorage('isZh') || getLocalStorage('isCN')
  if (cacheIsCN) {
    testIsCN = cacheIsCN === '1' || cacheIsCN === 'true'
    setLocalStorage('isCN', testIsCN)
  } else if (mode === 'local' || mode === 'test-local') {
    // local环境根据域名判断，test-accounts.updf.cn 对应海外，local-accounts.updf.cn 对应国内
    if (location.host.indexOf('test-accounts') >= 0) {
      testIsCN = false
    } else {
      testIsCN = true
    }
  }
  // 正式环境根据域名判断.cn为国内.com为海外
  const isCN = mode === 'prod' ? location.host.indexOf('updf.cn') >= 0 : testIsCN
  return isCN
}

/**
 * 获取默认语言
 * @param {boolean} isCN 是否是国内
 * @param {string} lang 默认语言
 * @returns {boolean}
 */
export const getLanguage = (isCN = false, lang = null) => {
  const defaultLang = isCN ? 'zh-CN' : 'en-US'
  const langCode = lang || getUrlQuery('language') || getLocalStorage('language') || defaultLang
  const language = getSupportLanguage(langCode)
  setLocalStorage('language', language)
  return language
}

/**
 * 获取客户端交互协议
 * @returns {string}
 */
export const getSchemeName = () => {
  const schemeName = getUrlQuery('schemeName') || getLocalStorage('schemeName') || ''
  return schemeName
}

/**
 * 设备类型，默认使用getOsName()获取，WEB，MAC，WIN，IOS，ANDROID，默认：获取当前系统类型
 * @returns {string}
 */
export const getDeviceType = () => {
  const deviceType = (getUrlQuery('deviceType') || getSessionStorage('deviceType') || getOSName()).toUpperCase()
  if (deviceType) setSessionStorage('deviceType', deviceType)
  return deviceType
}

export const getSource = () => {
  const source = getUrlQuery('source') || getSessionStorage('source') || getCookie('source') || 'Unknown'
  if (source) getSessionStorage('source', source)
  return source
}

/**
 * 渠道包 id：渠道ID，优先参数中的subpid，否则查询cookie中是否有
 * @returns {string}
 */
export const getSubPid = () => {
  const subPid = getUrlQuery('subPid') || getCookie('subPid') || getSessionStorage('subPid') || ''
  if (subPid) setSessionStorage('subPid', subPid)
  return subPid
}

/**
 * 产品名称：如果有subPid，则返回：UPDF+subPid，否则如果链接里有productName，则返回productName，否则返回UPDF
 * WEBSITE(官网),ONLINE(分享),WEB_AI(网页ai),WEB_SIGN(数字签名)
 * @returns {string}
 */
export const getProductName = () => {
  const subPid = getSubPid()
  const productName = (subPid ? `UPDF_${subPid}` : getUrlQuery('productName') || 'UPDF').toUpperCase()
  return productName
}

/**
 * 登录来源：checkout，website，online
 * @returns {string}
 */
export const getLoginFrom = () => {
  const loginFrom = getUrlQuery('loginFrom') || getSessionStorage('loginFrom')
  if (loginFrom) setSessionStorage('loginFrom', loginFrom)
  return loginFrom
}

/**
 * 邀请有礼获取分享key
 * @returns {string}
 */
export const getShareKey = () => {
  const shareKey = getUrlQuery('shareKey') || getSessionStorage('shareKey') || ''
  if (shareKey) setSessionStorage('shareKey', shareKey)
  return shareKey
}

/**
 * 邀请有礼获取分享类型：Email,Link,Social
 * @returns {string}
 */
export const getShareType = () => {
  const shareType = getUrlQuery('shareType') || getSessionStorage('shareType') || ''
  if (shareType) setSessionStorage('shareType', shareType)
  return shareType
}

/**
 * 获取token过期时间
 * @returns {string}
 */
export const getTokenExpired = () => {
  return getCookie(config.tokenExpiredKey)
}

/**
 * 获取登录token
 * @returns {string}
 */
export const getToken = () => {
  const token = getCookie(config.tokenKey)
  const expired = getTokenExpired()
  // 如果没有token或expired或expired已过期，返回空token
  if (!token || !expired || expired < new Date().getTime()) return ''
  return token
}

/**
 * 获取token中的uid
 * @returns {number}
 */
export const getTokenUid = (token = null) => {
  token = token || getToken()
  // 通过token解码uid
  if (token) {
    // token中的json使用了rawUrlEncoding编码，用'-'和'_'替换了'+'和'/'，解码需要替换回来(否则中文下会解码失败)
    const userDataJson = token.split('.')[1].replaceAll('-', '+').replaceAll('_', '/')
    const tokenData = JSON.parse(Base64.decode(userDataJson))
    if (tokenData) {
      return tokenData.Uid
    }
  }
  return 0
}

/**
 * 获取企业邀请用户邀请码
 * @returns {string}
 */
export const getInviteCode = () => {
  const inviteCode = getUrlQuery('inviteCode') || getSessionStorage('inviteCode') || ''
  if (inviteCode) setSessionStorage('inviteCode', inviteCode)
  return inviteCode
}
