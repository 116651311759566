import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', name: '_Home', redirect: { name: 'loginAccountInput' } },
  // 相当于入口文件，利用action跳转到不同对应页面
  { path: '/index/:action/:lang', name: 'index', component: () => import('@/view/index') },

  // 登录注册
  {
    path: '/login/',
    name: 'loginIndex',
    component: () => import('@/view/user/login/index'),
    children: [
      { path: '/login/wechat', name: 'loginWechat', component: () => import('@/view/user/login/pages/wechat') },
      
      { path: '/login/account-input', name: 'loginAccountInput', component: () => import('@/view/user/login/pages/account-input') },
      { path: '/login/account-code', name: 'loginAccountCode', component: () => import('@/view/user/login/pages/account-code') },
      { path: '/login/account-pass', name: 'loginAccountPass', component: () => import('@/view/user/login/pages/account-pass') },
      { path: '/login/account-sso', name: 'loginAccountSso', component: () => import('@/view/user/login/pages/account-sso') },
      { path: '/login/forgetpass-code', name: 'loginForgetpassCode', component: () => import('@/view/user/login/pages/forgetpass-code') },
      { path: '/login/forgetpass', name: 'loginForgetpass', component: () => import('@/view/user/login/pages/forgetpass') },
      { path: '/login/info-update', name: 'loginInfoUpdata', component: () => import('@/view/user/login/pages/info-update') },
      { path: '/login/info-report', name: 'loginInfoReport', component: () => import('@/view/user/login/pages/info-report') },
      { path: '/login/select-account', name: 'loginSelectAccount', component: () => import('@/view/user/login/pages/select-account') },
      { path: '/login/callback/:loginType', name: 'loginCallback', component: () => import('@/view/user/login/pages/callback') },
      { path: '/login/join-confirm', name: 'loginJoinConfirm', component: () => import('@/view/user/login/pages/join-confirm') },
      { path: '/login/join-success', name: 'loginJoinSuccess', component: () => import('@/view/user/login/pages/join-success') },
    ]
  },
  // 个人中心
  {
    path: '/account-center',
    name: 'accountCenter',
    component: () => import('@/view/user/center/index'),
    redirect: '/center/profile',
    children: [
      { path: '/center/profile', name: 'accountCenterProfile', component: () => import('@/view/user/center/pages/profile'), },
      { path: '/center/plans', name: 'accountCenterPlans', component: () => import('@/view/user/center/pages/plans'), },
      { path: '/center/redeem', name: 'accountCenterRedeem', component: () => import('@/view/user/center/pages/redeem'), },
      { path: '/center/orders', name: 'accountCenterOrders', component: () => import('@/view/user/center/pages/orders'), },
      { path: '/center/devices', name: 'accountCenterDevices', component: () => import('@/view/user/center/pages/devices'), },
      { path: '/center/settings', name: 'accountCenterSettings', component: () => import('@/view/user/center/pages/settings'), },
    ],
  },
  // 第三方授权成功回调页
  { path: '/account-center/bindAccount/callback/:bindType', name: 'bindAccountCallback', component: () => import('@/view/user/center/pages/bind-callback'), },
  // 其他端引入登录页时以iframe方式引入和交互
  { path: '/iframe/plan-list', name: 'iframePlanList', component: () => import('@/view/iframe/plan-list') },
  { path: '/iframe/login', name: 'iframeLogin', component: () => import('@/view/iframe/login') },
  { path: '/iframe/account-center', name: 'iframeAccountCenter', component: () => import('@/view/iframe/account-center') },
  { path: '/iframe/profile-dropdown', name: 'iframeProfileDropdown', component: () => import('@/view/iframe/profile-dropdown') },
  { path: '/iframe/feedback', name: 'iframeFeedback', component: () => import('@/view/iframe/feedback') },
  { path: '/iframe/google-one-tap', name: 'iframeGoogleOneTap', component: () => import('@/view/iframe/google-one-tap') },
  { path: '/*', name: 'notfound', redirect: { path: '/index/login/en-US?deviceType=WEB' } },

  // 管理员登陆
  { path: '/login/manager', name: 'loginManager', component: () => import('@/view/company/login/account-input') },
  // 选择账号
  { path: '/login/manager/select-account', name: 'loginManagerSelectAccount', component: () => import('@/view/company/login/select-account') },
  // 忘记密码验证
  { path: '/login/manager/forgetpass-code', name: 'loginManagerForgetpassCode', component: () => import('@/view/company/login/forgetpass-code') },
  // 忘记密码重置密码
  { path: '/login/manager/forgetpass', name: 'loginManagerForgetpass', component: () => import('@/view/company/login/forgetpass') },

  // 创建企业
  {
    path: '/company',
    name: 'company',
    component: () => import('@/view/company/create/index'),
    redirect: '/company/create',
    children: [
      { path: '/company/create',  name: 'companyCreate', component: () => import('@/view/company/create/create'), },
      { path: '/company/create/personal-info', name: 'createPersonalInfo', component: () => import('@/view/company/create/personal-info'), },
      { path: '/company/create/verify-info', name: 'createVerifyInfo', component: () => import('@/view/company/create/verify-info'), },
      { path: '/company/create/pass-info', name: 'createPassInfo', component: () => import('@/view/company/create/pass-info'), },
      { path: '/company/create/invite-colleagues', name: 'createInviteColleagues', component: () => import('@/view/company/create/invite-colleagues'),},
    ],
  },
  // 企业后台管理
  {
    path: '/company/management',
    name: 'companyManagement',
    component: () => import('@/view/company/management/index'),
    redirect: '/company/management/home',
    children: [
      { path: '/company/management/home', name: 'companyHome', component: () => import('@/view/company/management/home'), },
      { path: '/company/management/members', name: 'companyStaff', component: () => import('@/view/company/management/staff'), },
      { path: '/company/management/settings', name: 'companySettings', component: () => import('@/view/company/management/settings'), },
      { path: '/company/management/order', name: 'companyOrder', component: () => import('@/view/company/management/order'), },
    ],
  },
  // 活动页面
  { path: '/activity/login-success', name: 'activityLoginSuccess', component: () => import('@/view/activity/index') },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
